<template>
  <div id="searchProducts">
    <div class="headerBox">
      <div class="header">
        <div class="headerleft2" @click="$router.go(-1)">
          <van-icon name="arrow-left" />
        </div>
        <div class="headercenter2">
          <input
            v-model="inputTxt"
            placeholder="蜂胶囊"
            @change="inputChange($event)"
            @keyup.enter="handleSearch"
          />
          <img v-if="inputTxt" @click="delInput" src="../assets/close.png" />
        </div>
        <div class="headerright2" @click="handleSearch">搜索</div>
      </div>
    </div>

    <div class="loading" v-if="showloading">
      <van-loading type="spinner" color="#206C47" size="24px" vertical>
        加载中...
      </van-loading>
    </div>

    <div class="container" v-else>
      <div class="sort" v-if="type == 1">
        <div class="box">
          <div
            v-for="(item, index) in tabsList"
            :key="item.cid"
            class="sortItem sortItem1"
            :class="sortActive == index ? 'sortActive' : ''"
            @click="changeSort(index, item.cid)"
          >
            {{ item.pname }}
          </div>
        </div>
      </div>

      <div class="sort" v-if="type == 2">
        <div class="box box2">
          <div
            v-for="(item, index) in tabsList"
            :key="item.cate_id"
            class="sortItem"
            :class="sortActive == index ? 'sortActive' : ''"
            @click="changeSort(index, item.cate_id)"
          >
            {{ item.cate_name }}
          </div>
        </div>
      </div>
      <!-- 
      <div class="loading2" v-if="showloading2">
        <van-loading type="spinner" color="#206C47" size="24px" vertical>
          加载中...
        </van-loading>
      </div> -->

      <div>
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          :immediate-check="false"
          :error.sync="error"
          error-text="请求失败"
        >
          <!-- 如果是type=1,商品搜索页 -->
          <div class="list" v-show="type == 1">
            <div
              class="listItem"
              v-for="item in products"
              :key="item.id"
              @click="getDetails(item.id)"
            >
              <div class="pageimg">
                <img class="pageImg" :src="item.imgurl" />
              </div>
              <div class="pageName">{{ item.name }}</div>
            </div>
          </div>

          <!-- 如果是type=2,新闻搜索页 -->
          <div class="newsList" v-show="type == 2">
            <div
              class="newsItem"
              v-for="item in news"
              :key="item.Article_id"
              @click="getNewsdetail(item.Article_id)"
            >
              <div class="left">
                <img class="leftimg" :src="item.Article_imgurl" />
              </div>
              <div class="right">
                <div class="pageTitle">{{ item.Article_title }}</div>
                <div class="pageTime">{{ item.add_date }}</div>
              </div>
            </div>
          </div>
        </van-list>

        <div v-if="type == 1 && !loading">
          <div class="totals" v-if="total > 0">共{{ total }}件商品</div>
          <div class="totals2" v-if="total == 0">没有找到相关商品~</div>

          <div class="recommend" v-if="total == 0">
            <div class="recommendTitle">
              <div class="empty"></div>
              <div class="goods">推荐商品</div>
            </div>
            <div class="recommendGoods">
              <div class="list listno">
                <div
                  class="listItem"
                  v-for="item in recommend"
                  :key="item.id"
                  @click="getDetails(item.id)"
                >
                  <div class="pageimg">
                    <img class="pageImg" :src="item.imgurl" />
                  </div>
                  <div class="pageName">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="type == 2 && !loading">
          <div class="totals type2totals" v-if="total > 0">
            共{{ total }}条资讯
          </div>
          <div class="totals2 type2totals2" v-if="total == 0">
            没有找到相关资讯~
          </div>
        </div>
      </div>
    </div>

    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header2 from "@/components/Header2.vue";
import Footer from "@/components/Footer.vue";
import { Icon, Loading, List, Toast } from "vant";
import qs from "qs";

export default {
  components: {
    Header2,
    Footer,
    [Icon.name]: Icon,
    [Loading.name]: Loading,
    [List.name]: List,
    [Toast.name]: Toast,
  },
  data() {
    return {
      showloading: true,
      // showloading2: true,
      tabsList: [], //分类
      sortActive: 0, //分类高亮状态
      cid: 0, //当前的分类id
      titlePage: 1, //当前页
      total: 1, //数据总条数
      products: [], //商品列表数据
      news: [], //新闻列表数据
      loading: true,
      finished: false,
      error: false,
      inputTxt: "", //搜索框内容
      recommend: [], //推荐商品
      type: 1, //type1是商品搜索，2是新闻搜索
    };
  },
  mounted() {
    this.getRecommend();

    this.type = this.$route.query.type;
    // console.log(this.type); //type1是商品搜索，2是新闻搜索
    if (this.type == 1) {
      this.getSort(); //获取商品分类
      document.title = "商品搜索-无邪生物科技";
    } else {
      this.getNewsSort(); //获取新闻分类
      document.title = "资讯搜索-无邪生物科技";
    }
  },
  methods: {
    // 获取商品分类
    getSort() {
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=search&m=class_index"
        )
        .then((res) => {
          // console.log(res.List);
          if (res.status == 1) {
            let tabsList = res.List;
            tabsList.unshift({ cid: "0", pname: "全部商品" });
            this.tabsList = tabsList;
            this.cid = this.tabsList[0].cid;
            this.getData();
          }
        });
    },

    //   获取新闻分类
    getNewsSort() {
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=envelope&m=a_class"
        )
        .then((res) => {
          // console.log(res.List);
          if (res.status == 1) {
            let sort = res.List;
            sort.unshift({ cate_id: "0", cate_name: "全部" });
            this.tabsList = sort;
            // console.log(this.tabsList);
            this.cid = this.tabsList[0].cate_id;
            this.getNewsData();
          }
        });
    },

    // 获取商品列表
    getData() {
      if (this.cid == 0) {
        this.cid = ""; //cid为空则代表全部商品
      }
      let data1 = qs.stringify({
        page: this.titlePage, //当前页数
        cid: this.cid, //分类id
      });
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=product&m=product_list",
          data1
        )
        .then((res) => {
          if (res.status == 1) {
            // console.log(res.pro);
            this.products = [...this.products, ...res.pro];
            this.total = res.total; //数据总条数
            this.loading = false;
            this.showloading = false;
            // this.showloading2 = false;
          }
        })
        .catch(() => {
          this.error = true;
        });
    },

    // 获取新闻列表
    getNewsData() {
      if (this.cid == 0) {
        this.cid = "";
      }
      let data1 = qs.stringify({
        class_id: this.cid,
        page: this.titlePage,
      });
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=envelope&m=get_act",
          data1
        )
        .then((res) => {
          // console.log(res.article);
          if (res.status == 1) {
            this.news = [...this.news, ...res.article];
            this.total = res.total;
            this.loading = false;
            this.showloading = false;
            // this.showloading2 = false;
          }
        })
        .catch(() => {
          this.error = true;
        });
    },

    // 点击切换分类
    changeSort(index, id) {
      this.sortActive = index;
      this.cid = id;
      this.products = [];
      this.news = [];
      this.titlePage = 1;
      // this.showloading2 = true;
      this.loading = true;
      this.finished = false;
      this.error = false;
      if (this.type == 1) {
        // if (!this.inputTxt) {
        //   this.getData();
        // } else {
        //   this.handleSearch();
        // }
        this.inputTxt = "";
        this.getData();
      } else {
        this.inputTxt = "";
        this.getNewsData();
      }
    },

    // 下拉加载数据
    onLoad() {
      // 如果搜索框没有值,处理列表逻辑
      if (!this.inputTxt) {
        // 商品列表
        if (this.type == 1) {
          if (this.products.length >= this.total) {
            this.finished = true;
            this.loading = false;
          } else {
            this.titlePage += 1;
            this.getData();
          }
        } else {
          console.log(this.news.length);
          // 新闻列表
          if (this.news.length >= this.total) {
            this.finished = true;
            this.loading = false;
          } else {
            this.titlePage += 1;
            this.getNewsData();
          }
        }
      } else {
        // 如果搜索框有值,处理搜索逻辑
        if (this.type == 1) {
          // 搜索商品
          if (this.products.length >= this.total) {
            this.finished = true;
            this.loading = false;
          } else {
            this.titlePage += 1;
            this.handleSearchProducts();
          }
        } else {
          // 搜索新闻
          if (this.news.length >= this.total) {
            this.finished = true;
            this.loading = false;
          } else {
            this.titlePage += 1;
            this.handleSearchNews();
          }
        }
      }
    },

    // 输入框事件
    inputChange(e) {
      this.inputTxt = e.target.value;
    },

    // 点击搜索
    handleSearch() {
      if (!this.inputTxt) {
        Toast({
          message: "请输入搜索关键字",
          forbidClick: true,
          duration: 1000,
        });
      } else {
        this.titlePage = 1;
        this.products = [];
        this.news = [];
        // this.showloading2 = true;
        this.loading = true;
        this.finished = false;
        this.error = false;
        this.cid = 0;
        this.sortActive = 0;
        if (this.type == 1) {
          this.handleSearchProducts();
        } else {
          this.handleSearchNews();
        }
      }
    },

    // 搜索商品
    handleSearchProducts() {
      let data1 = qs.stringify({
        word: this.inputTxt,
        page: this.titlePage,
        cid: "",
      });
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=search&m=pro_search",
          data1
        )
        .then((res) => {
          // console.log(res);
          // this.showloading2 = false;
          this.loading = false;
          if (res.code == 0) {
            this.products = [];
            this.total = 0;
          }
          if (res.status == 1) {
            // this.products = res.pro;
            this.products = [...this.products, ...res.pro];
            this.total = res.total; //数据总条数
          }
        })
        .catch(() => {
          this.error = true;
        });
    },

    // 搜索新闻
    handleSearchNews() {
      this.sortActive = 0;
      let data1 = qs.stringify({
        keyword: this.inputTxt,
        page: this.titlePage,
      });
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=envelope&m=search1",
          data1
        )
        .then((res) => {
          // console.log(res);
          // this.showloading2 = false;
          this.loading = false;
          if (res.status == 0) {
            this.news = [];
            this.total = 0;
          }
          if (res.status == 1) {
            // this.news = res.article;
            this.news = [...this.news, ...res.article];
            this.total = res.total; //数据总条数
          }
        })
        .catch(() => {
          this.error = true;
        });
    },

    // 清空搜索框
    delInput() {
      // this.showloading2 = true;
      this.loading = true;
      this.inputTxt = "";
      this.products = [];
      this.news = [];
      this.total = 1;
      this.titlePage = 1;
      this.tabsList = [];
      this.sortActive = 0;
      this.cid = 0;
      this.loading = true;
      this.finished = false;
      this.error = false;
      this.getRecommend();
      this.type = this.$route.query.type;
      // console.log(this.type); //type1是商品搜索，2是新闻搜索
      if (this.type == 1) {
        this.getSort(); //获取商品分类
      } else {
        this.getNewsSort(); //获取新闻分类
      }
    },

    // 推荐商品
    getRecommend() {
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=product&m=tuji_product1"
        )
        .then((res) => {
          //   console.log(res);
          if (res.status == 1) {
            this.recommend = res.pro;
          }
        });
    },

    // 跳转到商品详情页
    getDetails(id) {
      // console.log(id);
      this.$router.push({ path: `/productDetail?id=${id}` });
    },

    // 跳转到新闻详情页
    getNewsdetail(id) {
      // console.log(id);
      this.$router.push({ path: `/newsDetail?id=${id}` });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  margin-top: 2.6875rem;
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 60%;
}
.loading2 {
  width: 100vw;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

// 头部
.headerBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
  background: #fff;
  z-index: 1000;
  border-bottom: 1px solid #ececec;
}
.header {
  padding: 0.40625rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerleft2 {
  color: #666666;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headercenter2 {
  flex: 1;
  height: 1.875rem;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 1rem;
  input {
    border: 1px solid #206c47;
    height: 100%;
    width: 100%;
    border-radius: 1.25rem;
    padding-left: 1rem;
  }
  img {
    position: absolute;
    right: 0.625rem;
    top: 50%;
    transform: translate(0, -50%);
    width: 0.875rem;
    height: 0.875rem;
  }
}
.headerright2 {
  background-color: #206c47;
  color: #fff;
  font-size: 16px;
  height: 1.875rem;
  border-radius: 0.9375rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

// 横向滚动条
.sort {
  // height: 2.875rem;
  padding: 0.2rem 1rem;
  box-shadow: 0 0 5px 1px #d2d2d2;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 2.6875rem;
  z-index: 99;
  .box {
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .box2 {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sortItem1 {
    margin-right: 3rem;
  }
  .sortItem {
    color: #666666;
    font-size: 0.8125rem;
    border-bottom: 2px solid #fff;
    white-space: nowrap;
    box-sizing: border-box;
    height: 100%;
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
  .sortActive {
    color: #206c47;
    border-bottom: 2px solid #206c47;
    padding: 0.5rem 0;
    box-sizing: border-box;
  }
}
// 隐藏横向滚动条
.sort::-webkit-scrollbar {
  display: none;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 7.25rem;
  .listItem {
    margin-bottom: 1.875rem;
    margin-left: 1rem;
    width: calc((100vw - 3rem) / 2);
    box-shadow: 0 0 5px 1px #e7e7e7;
    .pageimg {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .pageImg {
        margin-top: 0.125rem;
        width: calc((100vw - 3rem) / 2 - 4px);
      }
    }
    .pageName {
      color: #206c47;
      font-size: 14px;
      background-color: #fff;
      height: 7.3125rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.listno {
  margin-top: 1.875rem;
}

.newsList {
  margin-top: 7.25rem;
  .newsItem {
    padding: 1.5rem 1rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    &:nth-child(1) {
      padding-top: 0;
    }
    .left {
      .leftimg {
        height: 5rem;
      }
    }
    .right {
      flex: 1;
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .pageTitle {
        color: #206c47;
        font-size: 0.875rem;
      }
      .pageTime {
        color: #999999;
        font-size: 0.75rem;
        font-family: "DIN2";
      }
    }
  }
}

/deep/ .van-list__finished-text {
  margin-bottom: 1.5rem;
}
/deep/ .van-list__placeholder {
  margin-bottom: 1.5rem;
}

.totals {
  color: #999;
  font-size: 12px;
  text-align: center;
  margin-bottom: 1.875rem;
}
.totals2 {
  color: #999;
  font-size: 12px;
  text-align: center;
  margin-bottom: 3rem;
  margin-top: 1.25rem;
}
.type2totals {
  margin: 1.875rem;
}
.type2totals2 {
  margin-top: 2.875rem;
}

.recommend {
  .recommendTitle {
    position: relative;
    padding: 0 1rem;
    .empty {
      border-top: 1px solid #999;
      height: 1.5625rem;
    }
    .goods {
      position: absolute;
      top: -1.5rem;
      left: 50%;
      transform: translate(-50%);
      color: #333333;
      font-size: 1rem;
      font-weight: 700;
      background-color: #fff;
      width: 6.25rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>